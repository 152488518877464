import { getCommonParamsOptions } from '@/utils/common.js'

export const searchFormConfig = {
  formItems: [
    {
      field: 'sn',
      type: 'input',
      label: 'terminal.sn'
    },
    {
      field: 'operationType',
      type: 'select',
      label: 'log.operation-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('terminal_operation_type')
    },
    {
      field: 'responseCode',
      type: 'select',
      label: 'terminal.response-result',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('terminal_response_code')
    },
    {
      field: 'createdBy',
      type: 'input',
      label: 'general.operator'
    },
    {
      field: 'creationDate',
      type: 'datepicker',
      label: 'Operation Date',
      otherOptions: {
        type: 'daterange',
        format: 'YYYY-MM-DD',
        valueFormat: 'YYYY-MM-DD'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
